import AdminLayout from "../components/AdminLayout";
import styled from "styled-components";
import { useMutation, gql } from "@apollo/client";

// Define mutation
const ADD_EVENT = gql`
  mutation addEvent($title: String!, $date: String!, $pic: String!) {
    addEvent(title: $title, date: $date, pic: $pic) {
      id
      title
      date
      pic
    }
  }
`;

function AddEvent() {
  let inputTitle: any, inputDate: any, inputPic: any;
  const [addEvent, { loading, error }] = useMutation(ADD_EVENT);
  if (loading) return <div>Submitting...</div>;
  if (error) return <div>Submission error! ${error.message}</div>;

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const toBase64 = (file: any) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject(error);
      });

    const picfile = inputPic.files[0];

    const picBased64 = await toBase64(picfile);

    addEvent({
      variables: {
        title: inputTitle.value,
        date: inputDate.value,
        pic: picBased64,
      },
    });
  };

  return (
    <AdminLayout>
      <h2>Create a new event</h2>
      <form onSubmit={handleSubmit}>
        <Row>
          <label htmlFor="title">Event Title</label>
          <br />
          <input
            id="title"
            name="title"
            type="text"
            ref={(node) => {
              inputTitle = node;
            }}
          />
        </Row>
        <Row>
          <label htmlFor="date">Date</label>
          <br />
          <input
            id="date"
            name="date"
            type="date"
            ref={(node) => {
              inputDate = node;
            }}
          />
        </Row>
        <Row>
          <label htmlFor="picture">Picture</label>
          <br />
          <input
            id="picture"
            name="picture"
            type="file"
            ref={(node) => {
              inputPic = node;
            }}
          />
        </Row>
        <ButtonsWrapper>
          <button type="submit">submit</button>
        </ButtonsWrapper>
      </form>
    </AdminLayout>
  );
}

const Row = styled.div`
  margin-bottom: 10px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default AddEvent;
