import Layout from "./Layout";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { useQuery, gql, useLazyQuery } from "@apollo/client";
import { Navigate } from "react-router-dom";

const GET_IS_AUTH = gql`
  query {
    isAuth
  }
`;

const LOGOUT = gql`
  query {
    logout
  }
`;

type Props = {
  children: React.ReactNode;
};

function AdminLayout(props: Props) {
  const location = useLocation();
  const [logout] = useLazyQuery(LOGOUT, {
    fetchPolicy: "no-cache",
  });
  const { data: authData, refetch: refetchAuth } = useQuery(GET_IS_AUTH, {
    fetchPolicy: "no-cache",
  });

  const handleLogout = async (e: any) => {
    e.preventDefault();
    await logout();
    refetchAuth();
  };

  return (
    <Layout>
      {authData && authData.isAuth && (
        <>
          <Header>
            <MainMenuWrapper>
              <MenuItem current={location.pathname === "/admin"}>
                <LinkStyled to="/admin">Add event</LinkStyled>
              </MenuItem>
              <MenuItem current={location.pathname === "/admin-events"}>
                <LinkStyled to="/admin-events">Events</LinkStyled>
              </MenuItem>
              <MenuItem current={location.pathname === "/admin-subscribers"}>
                <LinkStyled to="/admin-subscribers">Subscribers</LinkStyled>
              </MenuItem>
              <MenuItem current={location.pathname === "/add-banners"}>
                <LinkStyled to="/add-banners">Add banner</LinkStyled>
              </MenuItem>
              <MenuItem current={location.pathname === "/admin-banners"}>
                <LinkStyled to="/admin-banners">Banners</LinkStyled>
              </MenuItem>
            </MainMenuWrapper>
            <Logout onClick={handleLogout}>logout</Logout>
          </Header>
          <div>{props.children}</div>
        </>
      )}
      {authData && !authData.isAuth && <Navigate replace to="/login" />}
    </Layout>
  );
}

const Header = styled.header`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;

const MainMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const Logout = styled.button`
  color: red;
`;

const MenuItem = styled.div<{ current: boolean }>`
  cursor: pointer;
  position: relative;
  line-height: 50px;
  margin-right: 25px;
  font-weight: ${(props) => (props.current ? 800 : 500)};
`;

const LinkStyled = styled(Link)`
  text-decoration: none;
  color: #0081c7;
  &:visited {
    color: #0081c7;
    text-decoration: none;
  }
`;

export default AdminLayout;
