import { ApolloError } from "@apollo/client/errors";
import type { Banner } from "../types";
import useTableSelection from "../hooks/useTableSelection";
import {
  ActionsBar,
  Table,
  HeaderCell,
  Cell,
  CellCheckbox,
  Checkbox,
} from "./TableStyles";

type Props = {
  banners: Banner[];
  loading: boolean;
  error?: ApolloError;
  onDelete: (bannerIDs: number[]) => void;
};

function BannerTable(props: Props) {
  const { selectedIds, toggleSelection, toggleSelectAll } = useTableSelection(
    props.banners
  );

  if (props.loading) return <p>Loading...</p>;

  return (
    <>
      <ActionsBar>
        <button
          disabled={selectedIds.length === 0}
          onClick={() => props.onDelete(selectedIds)}
        >{`Delete ${selectedIds.length ? selectedIds.length : ""}`}</button>
      </ActionsBar>
      <Table>
        <thead>
          <tr>
            <HeaderCell width={50}>
              <Checkbox type="checkbox" onChange={toggleSelectAll} />
            </HeaderCell>
            <HeaderCell width={500}></HeaderCell>
          </tr>
        </thead>
        <tbody>
          {props.banners.map(({ id, company }) => {
            return (
              <tr key={id}>
                <CellCheckbox>
                  <Checkbox
                    type="checkbox"
                    onChange={() => toggleSelection(id)}
                    checked={selectedIds.includes(id)}
                  />
                </CellCheckbox>
                <Cell>{company}</Cell>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

export default BannerTable;
