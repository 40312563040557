import { ApolloError } from "@apollo/client/errors";
import moment from "moment";
import type { Event } from "../types";
import useTableSelection from "../hooks/useTableSelection";
import {
  ActionsBar,
  Table,
  HeaderCell,
  Cell,
  CellCheckbox,
  Checkbox,
} from "./TableStyles";

//admin facing table

type Props = {
  loading: boolean;
  error?: ApolloError;
  data: Event[];
  isLink: boolean;
  onDelete: (eventIds: number[]) => void;
};

type EventRowProps = {
  event: Event;
  selectedIds: number[];
  toggleSelection: (eventId: number) => void;
};

// id: number;
//   title: string;
//   pic: string;
//   date: string;

function EventRow(props: EventRowProps) {
  let eventDate = new Date(props.event.date);
  let outputDate = moment(eventDate).format("DD MMM YYYY");

  return (
    <tr key={props.event.id}>
      <CellCheckbox>
        <Checkbox
          type="checkbox"
          onChange={() => props.toggleSelection(props.event.id)}
          checked={props.selectedIds.includes(props.event.id)}
        />
      </CellCheckbox>
      <Cell>{props.event.title}</Cell>
      <Cell>{outputDate}</Cell>
    </tr>
  );
}

function EventsTable(props: Props) {
  const { selectedIds, toggleSelection, toggleSelectAll } = useTableSelection(
    props.data
  );

  if (props.loading) return <p>Loading...</p>;
  if (props.error) return <p>Error : {props.error.message}</p>;

  return (
    <>
      <ActionsBar>
        <button
          disabled={selectedIds.length === 0}
          onClick={() => props.onDelete(selectedIds)}
        >{`Delete ${selectedIds.length ? selectedIds.length : ""}`}</button>
      </ActionsBar>
      <Table>
        <thead>
          <tr>
            <HeaderCell width={50}>
              <Checkbox type="checkbox" onChange={toggleSelectAll} />
            </HeaderCell>
            <HeaderCell width={500}></HeaderCell>
            <HeaderCell></HeaderCell>
          </tr>
        </thead>
        <tbody>
          {props.data.map((eventItem) => (
            <EventRow
              key={eventItem.id}
              event={eventItem}
              selectedIds={selectedIds}
              toggleSelection={toggleSelection}
            />
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default EventsTable;
