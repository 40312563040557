import AdminLayout from "../components/AdminLayout";
import styled from "styled-components";
import { useMutation, gql } from "@apollo/client";

const ADD_BANNER = gql`
  mutation addBanner($company: String!, $link: String!, $pic: String!) {
    addBanner(company: $company, link: $link, pic: $pic) {
      id
      company
      link
      pic
    }
  }
`;

function AddBanner() {
  let inputCompany: any, inputLink: any, inputPic: any;
  const [addBanner, { loading, error }] = useMutation(ADD_BANNER);
  if (loading) return <div>Submitting...</div>;
  if (error) return <div>Submission error! ${error.message}</div>;

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const toBase64 = (file: any) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject(error);
      });

    const picfile = inputPic.files[0];

    const picBased64 = await toBase64(picfile);

    addBanner({
      variables: {
        company: inputCompany.value,
        link: inputLink.value,
        pic: picBased64,
      },
    });
  };

  return (
    <AdminLayout>
      <h2>Add a new Banner</h2>
      <form onSubmit={handleSubmit}>
        <Row>
          <label htmlFor="company">Company</label>
          <br />
          <input
            id="company"
            name="company"
            type="text"
            ref={(node) => {
              inputCompany = node;
            }}
          />
        </Row>
        <Row>
          <label htmlFor="company">Link</label>
          <br />
          <input
            id="company"
            name="company"
            type="text"
            ref={(node) => {
              inputLink = node;
            }}
          />
        </Row>
        <Row>
          <label htmlFor="picture">Banner</label>
          <br />
          <input
            id="picture"
            name="picture"
            type="file"
            ref={(node) => {
              inputPic = node;
            }}
          />
        </Row>
        <ButtonsWrapper>
          <button type="submit">submit</button>
        </ButtonsWrapper>
      </form>
    </AdminLayout>
  );
}

const Row = styled.div`
  margin-bottom: 10px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default AddBanner;
