import React from "react";
import Layout from "../components/Layout";
import EventsList from "../components/EventsList";
import { useQuery, gql } from "@apollo/client";
import StayTuned from "../components/StayTuned";

const GET_EVENTS = gql`
  query {
    events(timeDirection: "upcoming") {
      id
      title
      pic
      date
    }
  }
`;

function UpcomingEvents() {
  const { loading, error, data } = useQuery(GET_EVENTS, {
    fetchPolicy: "no-cache",
  });

  // const queryObjs = queryNextEvents()

  return (
    <Layout>
      {data?.events.length === 0 ? (
        <StayTuned />
      ) : (
        <EventsList
          loading={loading}
          error={error}
          data={data}
          isLink={false}
        />
      )}
    </Layout>
  );
}

export default UpcomingEvents;
