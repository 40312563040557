import React from "react";
import Layout from "../components/Layout";
import EventsList from "../components/EventsList";
import { useQuery, gql } from "@apollo/client";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";

const GET_EVENTS = gql(/* GraphQL */ `
  query events($timeDirection: String!, $perPage: Int!, $pageNumber: Int!) {
    events(
      timeDirection: $timeDirection
      perPage: $perPage
      pageNumber: $pageNumber
    ) {
      id
      title
      pic
      date
      lastPage
      currentPage
    }
  }
`);

function PastEvents() {
  const pageParam = useParams();
  const pageNumber = Number(pageParam.pageNumber);
  const { loading, error, data } = useQuery(GET_EVENTS, {
    variables: {
      timeDirection: "past",
      perPage: 4,
      pageNumber: pageNumber,
    },
    fetchPolicy: "no-cache",
  });

  const pageIndex = Array.from(
    { length: data?.events[0].lastPage },
    (_, i) => i + 1
  );

  return (
    <Layout>
      {!loading && (
        <PageSelectionBar>
          {pageIndex.map((linkNumber) => {
            return (
              <ControlButton key={linkNumber}>
                {linkNumber === pageNumber ? (
                  <Current>{linkNumber}</Current>
                ) : (
                  <LinkStyled to={`/past-events/${linkNumber}`}>
                    {linkNumber}
                  </LinkStyled>
                )}
              </ControlButton>
            );
          })}
        </PageSelectionBar>
      )}
      <EventsList loading={loading} error={error} data={data} isLink={false} />
    </Layout>
  );
}

const PageSelectionBar = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ControlButton = styled.div`
  padding: 4px 8px 4px 8px;
  color: #0081c7;
`;

const LinkStyled = styled(Link)`
  text-decoration: none;
  color: #0081c7;
  font-size: 18px;
  &:visited {
    color: #0081c7;
  }
`;

const Current = styled.div`
  font-weight: 600;
  font-size: 25px;
`;

export default PastEvents;
