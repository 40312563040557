import { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import type { Banner as BannerType } from "../types";

function shuffle(array: any[]) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

/*const getBannersWidth = async (banners: BannerType[]) => {
  const getBannerWidth = async (pic: string) => {
    let img = new Image();
    img.src = pic;
    await img.decode();
    return [img.height, img.width];
  };
  let widthArrayPromises = banners.map((banner) => getBannerWidth(banner.pic));
  const dimArray = await Promise.all(widthArrayPromises);
  const targetHeight = 60;
  const scaledWidths = dimArray.map((dimArrayItem) => {
    return (targetHeight / dimArrayItem[0]) * dimArrayItem[1];
  });

  const combinedWidth = scaledWidths.reduce(
    (partialSum, a) => partialSum + a,
    0
  );
  return combinedWidth;
};*/

type Props = {
  banners: BannerType[];
};

function BannersCarousel(props: Props) {
  const [calcWidth, setCalcWidth] = useState(0);

  useEffect(() => {
    const MARGIN_WIDTH = 20;
    const combinedImagesWidth = async () => {
      //const combinedImagesWidth = await getBannersWidth(props.banners);
      //const width = MARGIN_WIDTH * props.banners.length + combinedImagesWidth;
      const width = props.banners.length * (MARGIN_WIDTH + 125);
      setCalcWidth(width);
    };

    combinedImagesWidth();
  }, [props.banners]);

  const banners = shuffle(props.banners);

  const displayBanners = [...banners, ...banners].map((banner, index) => ({
    ...banner,
    id: `${banner.id}${index}`,
  }));

  return (
    <Wrapper width={calcWidth}>
      <Container>
        {displayBanners.map((banner) => {
          return (
            <Banner width={calcWidth} key={banner.id}>
              <a href={banner.link} target="_blank" rel="noopener noreferrer">
                <ImageStyled src={banner.pic} alt={banner.company} />
              </a>
            </Banner>
          );
        })}
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div<{ width: number }>`
  margin: 0 auto;
  overflow: hidden;
  max-width: ${(props) => props.width}px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const slide = (width: number) => keyframes`
  from { transform: translateX(0px); }
  to { transform: translateX(-${width}px); }
`;

const Banner = styled.div<{ width: number }>`
  margin-right: 20px;
  animation-name: ${(props) => slide(props.width)};
  animation-iteration-count: infinite;
  animation-duration: 30s;
  animation-timing-function: linear;
`;

const ImageStyled = styled.img`
  width: 125px;
`;

export default BannersCarousel;
