import styled from "styled-components";

export const ActionsBar = styled.div`
  padding: 20px 0;
`;

export const ActionsButton = styled.button`
  margin-right: 10px;
`;

export const Table = styled.table`
  border-collapse: collapse;
`;

export const HeaderCell = styled.th<{ width?: number }>`
  width: ${(props) => props.width}px;
  text-align: center;
  border: 1px solid rgb(190, 190, 190);
  padding: 10px;
`;

export const Cell = styled.td`
  border: 1px solid rgb(190, 190, 190);
  padding: 10px;
`;

export const CellCheckbox = styled(Cell)`
  text-align: center;
`;

export const Checkbox = styled.input`
  width: 25px;
  padding: 5px;
`;
