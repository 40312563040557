import React from "react";
import AdminLayout from "../components/AdminLayout";
import BannerTable from "../components/BannerTable";
import { useQuery, gql, useMutation } from "@apollo/client";

const GET_BANNERS = gql`
  query {
    banners {
      id
      company
      link
    }
  }
`;

const DELETE_BANNERS = gql`
  mutation deleteBanners($bannerIDs: [Int!]!) {
    deleteBanners(bannerIds: $bannerIDs)
  }
`;

function AdminBanners() {
  const { loading, error, data, refetch } = useQuery(GET_BANNERS, {
    fetchPolicy: "no-cache",
  });

  const [deleteBanners] = useMutation(DELETE_BANNERS);

  const deleteBanner = async (bannerIDs: number[]) => {
    await deleteBanners({
      variables: {
        bannerIDs: bannerIDs,
      },
    });

    await refetch();
  };

  if (data) {
    return (
      <AdminLayout>
        <BannerTable
          banners={data.banners}
          loading={loading}
          error={error}
          onDelete={deleteBanner}
        />
      </AdminLayout>
    );
  } else {
    return null;
  }
}

export default AdminBanners;
