import React from "react";
import AdminLayout from "../components/AdminLayout";
import EventsTable from "../components/EventsTable";
import { useQuery, gql, useMutation } from "@apollo/client";

const GET_EVENTS = gql`
  query {
    allEvents {
      id
      title
      date
    }
  }
`;

// Define mutation
const DELETE_EVENTS = gql`
  mutation deleteEvents($eventIds: [Int!]!) {
    deleteEvents(eventIds: $eventIds)
  }
`;

function AdminEvents() {
  const { loading, error, data, refetch } = useQuery(GET_EVENTS, {
    fetchPolicy: "no-cache",
  });
  const [deleteEvents] = useMutation(DELETE_EVENTS);

  const handleDelete = async (eventIds: number[]) => {
    await deleteEvents({
      variables: {
        eventIds: eventIds,
      },
    });

    await refetch();
  };

  return (
    <AdminLayout>
      <EventsTable
        loading={loading}
        error={error}
        data={data?.allEvents || []}
        isLink={true}
        onDelete={handleDelete}
      />
    </AdminLayout>
  );
}

export default AdminEvents;
