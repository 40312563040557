import Layout from "../components/Layout";
import styled from "styled-components";
import { useLazyQuery, gql, useQuery } from "@apollo/client";
import { Navigate } from "react-router-dom";

const LOGIN = gql`
  query login($username: String!, $password: String!) {
    login(username: $username, password: $password)
  }
`;

const GET_IS_AUTH = gql`
  query {
    isAuth
  }
`;

function Login() {
  const { data: authData } = useQuery(GET_IS_AUTH, {
    fetchPolicy: "no-cache",
  });
  const [login, { loading, error, data }] = useLazyQuery(LOGIN, {
    fetchPolicy: "no-cache",
  });
  let inputName: any, inputPassword: any;
  if (loading) return <div>Login...</div>;
  if (error) return <div>Login error! ${error.message}</div>;

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    await login({
      variables: {
        username: inputName.value,
        password: inputPassword.value,
      },
    });
  };
  return (
    <Layout>
      {authData && authData.isAuth && <Navigate replace to="/admin" />}
      {data && data.login && <Navigate replace to="/admin" />}
      <h2>Sign in</h2>
      <form onSubmit={handleSubmit}>
        <Row>
          <label htmlFor="name">Name</label>
          <br />
          <input
            id="name"
            name="name"
            type="text"
            ref={(node) => {
              inputName = node;
            }}
          />
        </Row>
        <Row>
          <label htmlFor="password">Password</label>
          <br />
          <input
            id="password"
            name="password"
            type="password"
            ref={(node) => {
              inputPassword = node;
            }}
          />
        </Row>
        <button type="submit">Login</button>
      </form>
    </Layout>
  );
}

const Row = styled.div`
  margin-bottom: 10px;
`;

export default Login;
