import React from "react";
import styled from "styled-components";
import TopMenu from "./TopMenu";

type Props = {
  children: React.ReactNode;
};

function Layout(props: Props) {
  return (
    <Root>
      <TopMenu />
      <Content>{props.children}</Content>
    </Root>
  );
}

const Root = styled.div`
  width: 100%;
`;

const Content = styled.div`
  width: 960px;
  margin: 0 auto;

  @media (max-width: 960px) {
    margin: 0 20px;
    width: calc(100% - 40px);
  }
`;

export default Layout;
