import { ApolloError } from "@apollo/client/errors";
import type { Subscriber } from "../types";
import useTableSelection from "../hooks/useTableSelection";
import {
  ActionsBar,
  ActionsButton,
  Table,
  HeaderCell,
  Cell,
  CellCheckbox,
  Checkbox,
} from "./TableStyles";

type Props = {
  loading: boolean;
  error?: ApolloError;
  data: Subscriber[];
  isLink: boolean;
  onDelete: (subscriberIds:number[]) => void;
};

function EventsTable(props: Props) {
  const { selectedIds, toggleSelection, toggleSelectAll } = useTableSelection(
    props.data
  );

  if (props.loading) return <p>Loading...</p>;
  if (props.error) return <p>Error : {props.error.message}</p>;

  const csvContent =
    "data:text/csv;charset=utf-8," +
    props.data.map((row) => row.email).join("\n");
  const encodedUri = encodeURI(csvContent);

  return (
    <>
      <ActionsBar>
        <ActionsButton disabled={selectedIds.length === 0} onClick={()=>props.onDelete(selectedIds)}>{`Delete ${
          selectedIds.length ? selectedIds.length : ""
        }`}</ActionsButton>
        <a href={encodedUri} download="BF-subscribers.csv">
          Export as CSV
        </a>
      </ActionsBar>
      <Table>
        <thead>
          <tr>
            <HeaderCell width={50}>
              <Checkbox type="checkbox" onChange={toggleSelectAll} />
            </HeaderCell>
            <HeaderCell width={500}></HeaderCell>
          </tr>
        </thead>
        <tbody>
          {props.data.map(({ id, email }) => {
            return (
              <tr key={id}>
                <CellCheckbox>
                  <Checkbox
                    type="checkbox"
                    onChange={() => toggleSelection(id)}
                    checked={selectedIds.includes(id)}
                  />
                </CellCheckbox>
                <Cell>{email}</Cell>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

export default EventsTable;
