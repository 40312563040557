import { useMutation, gql } from "@apollo/client";
import styled from "styled-components";

const ADD_SUBSCRIBER = gql`
  mutation addSubscriber($email: String!) {
    addSubscriber(email: $email) {
      email
    }
  }
`;

type Props = {
  isOpen: boolean;
};

function SubscriptionBar(props: Props) {
  const [addSubscriber, { data, error }] = useMutation(ADD_SUBSCRIBER);
  let inputName: any;

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    addSubscriber({
      variables: {
        email: inputName.value,
      },
    });
  };

  if (error) return <div>Submission error! ${error.message}</div>;

  return (
    <Header isOpen={props.isOpen}>
      {props.isOpen ? (
        data ? (
          <Success>Thank you for your Subscription!</Success>
        ) : (
          <Form onSubmit={handleSubmit}>
            <label htmlFor="name">Email:</label>
            <br />
            <input
              id="email"
              name="name"
              type="email"
              ref={(node) => {
                inputName = node;
              }}
            />
            <button type="submit">Subscribe</button>
          </Form>
        )
      ) : null}
    </Header>
  );
}

const Header = styled.header<{ isOpen: boolean }>`
  padding: 0 30px 0 30px;
  height: 50px;
  max-height: ${(props) => (props.isOpen ? 50 : 0)}px;
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  background-color: #ffffff;
  border-bottom: 1px solid #0081c7;
  position: sticky;
  top: 0;
  transition: all 0.4s ease-in-out;
  @media (max-width: 550px) {
    padding: 0 5px 0 5px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  max-width: 100%;
  > * {
    margin-right: 10px;
  }
`;

const Success = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
`;

export default SubscriptionBar;
