import React from "react";
import AdminLayout from "../components/AdminLayout";
import SubscribersTable from "../components/SubscribersTable";
import { useQuery, gql, useMutation } from "@apollo/client";

const GET_EVENTS = gql`
  query {
    subscribers {
      id
      email
      isActive
    }
  }
`;

// Define mutation
const DELETE_SUBSCRIBERS = gql`
  mutation deleteSubscribers($subscriberIds: [Int!]!) {
    deleteSubscribers(subscriberIds: $subscriberIds)
  }
`;

function AdminSubscribers() {
  const { loading, error, data, refetch } = useQuery(GET_EVENTS, {
    fetchPolicy: "no-cache",
  });
  const [deleteSubscribers] = useMutation(DELETE_SUBSCRIBERS);

  const handleDelete = async (subscriberIds:number[]) => {

    await deleteSubscribers({
      variables: {
        subscriberIds,
      },
    });

    await refetch()
  };
  return (
    <AdminLayout>
      <SubscribersTable
        loading={loading}
        error={error}
        data={data?.subscribers || []}
        isLink={true}
        onDelete={handleDelete}
      />
    </AdminLayout>
  );
}

export default AdminSubscribers;
