import Layout from "../components/Layout";
import Event from "../components/Event";
import { useQuery, gql } from "@apollo/client";
import styled from "styled-components";
import BannersCarousel from "../components/BannersCarousel";
import StayTuned from "../components/StayTuned";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";

const GET_NEXTEVENTS = gql`
  query {
    nextevent {
      id
      title
      date
      pic
    }
  }
`;

const GET_BANNERS = gql`
  query {
    banners {
      id
      company
      pic
      link
    }
  }
`;

function Home() {
  const {
    loading: eventLoading,
    error: eventError,
    data: eventData,
  } = useQuery(GET_NEXTEVENTS, {
    fetchPolicy: "no-cache",
  });

  const { data: bannerData, loading: bannerLoading } = useQuery(GET_BANNERS, {
    fetchPolicy: "no-cache",
  });

  const isEventday = () => {
    let currentDay = new Date();
    var dd = String(currentDay.getDate()).padStart(2, "0");
    var mm = String(currentDay.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = currentDay.getFullYear();

    const compareDate = (yyyy + "-" + mm + "-" + dd).toString(); //resolve copy by reference

    if (eventData.nextevent[0].date === compareDate) {
      return true;
    }
    return false;
  };

  return (
    <Layout>
      <h2>What is Blue Friday?</h2>
      <p>
        Blue Friday - Maritime Tech Talk aims to bring people together from
        industry and academics to share and exchange recent advancements in the
        sectors related to the maritime technology.
      </p>
      <p>
        The tech talks take place always on friday 13:00 CET and will be held
        online on a irregular base. Dates will be announced in prior.
      </p>
      <p>
        Interested? Just subscribe for updates. Don't worry, we won't spam your
        E-Mail folder, just inform you when new topics and date are disclosed.
        Everybody is welcome to attend.
      </p>
      {eventData && eventData.nextevent.length > 0 ? (
        <>
          <h4>Next Event:</h4>
          <Event
            loading={eventLoading}
            error={eventError}
            data={eventData}
            isLink={false}
          />
          <h5>
            <center>
              {isEventday() ? (
                <>
                  It's a event at 13:00 CET today!
                  <a
                    href="https://uni-rostock-de.zoom.us/j/69269181012?pwd=WHF2WWVKZWtWbDV3ZFhKVC95ejBSZz09"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <JoinButton>join via zoom</JoinButton>
                  </a>
                </>
              ) : null}
            </center>
          </h5>
        </>
      ) : eventLoading ? (
        <SyledBox sx={{ width: "100%" }}>
          <LinearProgress />
        </SyledBox>
      ) : (
        <StayTuned />
      )}
      <h4>Supported by:</h4>
      {bannerLoading ? (
        <SyledBox sx={{ width: "100%" }}>
          <LinearProgress />
        </SyledBox>
      ) : bannerData ? (
        <BannersCarousel banners={bannerData?.banners} />
      ) : null}
      <h4>BlueFriday is a cooperation of:</h4>
      <BannerBlock>
        <Image src={"images/uro.png"} alt="banner URO" width={20} />
        <Image src={"images/tuhh.jpeg"} alt="banner TUHH" width={15} />
        <Image src={"images/ude.jpeg"} alt="banner UDE" width={15} />
        <Image src={"images/tub.jpeg"} alt="banner TUB" width={10} />
      </BannerBlock>
    </Layout>
  );
}

const BannerBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Image = styled.img<{ width: number }>`
  width: ${(props) => props.width}%;
`;

const JoinButton = styled.div`
  background-color: rgb(222, 222, 222);
  border: none;
  color: black;
  padding: 4px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
`;

const SyledBox = styled(Box)`
  padding: 16px 0px 0px 0px;
`;

export default Home;
